<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn
      v-if="!toDashboardVisible()"
      class="mr-3 primary"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="title"
    />

    <v-spacer />

    <div v-if="isSearchVisible()" style="min-width: 50%;">
      <v-text-field
        v-model="searchPhrase"
        color="secondary"
        hide-details
        style="min-width: 50%;"
        clearable
        @keydown.enter="doSearch"
      >
        <template #append-outer>
          <v-btn
            class="mt-n2 primary"
            elevation="1"
            fab
            small
            @click="doSearch"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </div>
    <div class="mx-3" />

    <v-btn
      v-if="toDashboardVisible()"
      class="ml-2"
      min-width="0"
      text
      @click="toDashboard"
    >
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <v-btn
      v-if="toOperatorVisible()"
      class="ml-2"
      min-width="0"
      text
      @click="toOperator"
    >
      <v-icon>mdi-printer-3d</v-icon>
    </v-btn>
    <!-- <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >

      <template #activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">

          <v-badge color="red" overlap bordered>
            <template #badge>
              <span>5</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu> -->

    <v-btn v-if="isUserVisible()" class="ml-2" min-width="0" text to="/user">
      <v-icon>mdi-account</v-icon>
    </v-btn>

    <LocaleSwitcher />
  </v-app-bar>
</template>

<script>
// Components
// import { VHover, VListItem } from "vuetify/lib";
import LocaleSwitcher from './LocaleSwitcher.vue';

// Utilities
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'DashboardCoreAppBar',

  components: {
    LocaleSwitcher
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  emits: ['search'],
  data: () => ({
    notifications: [
      'Mike John Responded to your email',
      'You have 5 new tasks',
      "You're now friends with Andrew",
      'Another Notification',
      'Another one'
    ],
    searchPhrase: null
  }),

  computed: {
    ...mapState(['drawer']),
    title() {
      const route = this.$route.name;
      return this.$t(`${route}.header`);
    }
  },

  methods: {
    toDashboardVisible() {
      const route = this.$route.name;
      const prefix = this.$store?.state?.session?.prefix || '';
      console.log('prefix: ', prefix);
      return (
        (prefix == '/operator/' || route == 'Operator Login') &&
        route != 'Dashboard Login'
      );
    },
    toOperatorVisible() {
      let route = this.$route.name;
      return (
        (this.$store.state?.session?.prefix == '/dashboard/' ||
          route == 'Dashboard Login') &&
        route != 'Operator Login'
      );
    },
    isLogin() {
      let route = this.$route.name;
      return route == 'Dashboard Login' || route == 'Operator Login';
    },
    isSearchVisible() {
      const searchVisible = !this.toDashboardVisible() && !this.isLogin();
      console.log('toDashboardVisible:', this.toDashboardVisible());
      console.log('isLogin:', this.isLogin());
      console.log('searchVisible:', searchVisible);
      return searchVisible;
    },
    isUserVisible() {
      return this.$store.state.session.loggedIn;
    },
    doSearch() {
      // this.$root.$emit('search', this.searchPhrase);
      // /dashboard/search
      console.log('AppBar.doSearch()');
      if (this.searchPhrase?.length == 0) {
        return;
      }

      // if (this.$route?.name == 'Search') {
      //   this.$router.go({
      //     name: 'Search',
      //     params: { search: this.searchPhrase }
      //   });
      // } else {
      console.log('push');
      this.$router.push({
        name: 'Search',
        params: { search: this.searchPhrase }
      });
      // }
    },
    ...mapMutations({
      setDrawer: 'SET_DRAWER'
    }),
    toDashboard() {
      this.$store.commit('LOGOUT');
      this.$store.commit('SET_PREFIX', {
        prefix: '/dashboard/'
      });
      this.$router.push({ name: 'Dashboard Login' });
    },
    toOperator() {
      this.$store.commit('LOGOUT');
      this.$store.commit('SET_PREFIX', {
        prefix: '/operator/'
      });
      this.$router.push({ name: 'Operator Login' });
    }
  }
};
</script>
<style lang="css"></style>

<template>
  <div class="locale-switcher">
    <select v-model="$i18n.locale">
      <option value="en">English</option>
      <option value="fr">Français</option>
      <option value="de">Deutsche</option>
    </select>
  </div>
</template>

<script>
import { localize } from 'vee-validate';
import store from '@/store';

export default {
  watch: {
    '$i18n.locale': function(newLocale) {
      store.commit('SET_LOCALE', newLocale);
      this.$root.$emit('locale', newLocale);
      localize(newLocale);
    }
  }
};
</script>
